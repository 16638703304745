<template>
	 <b-modal size="sm" id="modal-edit-budget" :title="'Actualizar presupuesto: '+budget.year" ok-only hide-footer no-close-on-backdrop ref="modal-edit-budget">

		<b-overlay :show="isUpdatingBudget ">
			<ValidationObserver ref="updateCondominiumForm" v-slot="{ invalid }">

				<b-form @submit.prevent="updateBudgetYear">
					<b-row>

						<b-col md="12">
							<ValidationProvider rules="required" name="Cuota Condominal">
								<b-form-group label="Cuota Condominal" slot-scope="{ valid, errors }">
									<b-form-input
										class="form-control"
										type="text"
										v-model="budget.amountBudget"
										placeholder="0.00"
										:state="errors[0] ? false : valid ? true : null"
									/>
									<b-form-invalid-feedback v-for="error in errors" :key="error">
										{{ error }} <br class="p-0">
									</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<b-col md="12">
						<!-- Periodicidad solo se puede modificar si este viene nulo -->
							<ValidationProvider name="periodicidad" rules="required">
								<b-form-group label="Periodicidad" slot-scope="{ valid, errors }">
									<b-form-select
										:state="errors[0] ? false : valid ? true : null"
										v-model="budget.periodicity"

									>
										<!-- :disabled="budget.periodicity !== null" -->
										<option :value="null">Seleccione una periodicidad</option>
										<option
											v-for="currency in periodicityOptions"
											:key="currency.id"
											:value="currency.id"
										>
											{{ currency.name }}
										</option>
									</b-form-select>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<!-- Divisa solo se puede modificar si este viene nulo -->
						<b-col md="12">
							<ValidationProvider name="divisa" rules="required">
								<b-form-group label="Divisa" slot-scope="{ valid, errors }">
									<b-form-select
										:state="errors[0] ? false : valid ? true : null"
										v-model="budget.currency"
									>
										<!-- :disabled="budget.currency !== null" -->
										<option :value="null">Seleccione una divisa</option>
										<option
											v-for="currency in currencies"
											:key="currency.id"
											:value="currency.id"
										>
											{{ currency.value }}
										</option>
									</b-form-select>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>


					</b-row>

					<div class="btn-modal">
						<b-button type="submit"  variant="primary"  :disabled="invalid || isUpdatingBudget">
							Actualizar
						</b-button>
					</div>
				</b-form>

			</ValidationObserver>
		</b-overlay>

    </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import Ripple from 'vue-ripple-directive'

import { showAlertMessage, toCurrency, sweetAlert, numberWithCommas } from '@/helpers/helpers'
import { condominalFeePeriodicity } from "@/data/fivesClub"
import { utils } from "@/modules/fivesClub/mixins/utils";

export default {
	mixins: [utils],
    directives: {Ripple, },
    props:{
		budget:{
			type: Object,
			required: true,
		}
    },
    data() {
        return {
            selectedYear: new Date().getFullYear(),
            showFormEditBudget: false,
            currencies:[
                { id : 1, value : 'USD',  },
                { id : 2, value : 'MXN',  },
            ],
			periodicityOptions: condominalFeePeriodicity,
            isUpdatingBudget: false,
        }
    },
    computed:{
        ...mapState('start', ['hotels']),
		...mapState('auth', ['user']),
    },
    methods:{
		...mapActions('fivesClubCatalogs', [ 'updateCondominalFee' ]),
        ...mapMutations('fivesClubCatalogs',['setSelectedYearBudgetCondominium']),

        formatAmount(amount){
			return numberWithCommas(amount)
		},
        async updateBudgetYear(){
            this.isUpdatingBudget = true
			this.budget.userId = this.user.idUser

			const response = await this.updateCondominalFee(this.budget)
			const { status, message } = response

			const year = JSON.parse( JSON.stringify( this.budget.year ) ) // lo necesito, para ocupar en setSelectedYearBudgetCondominium

			if( status ){
				showAlertMessage("Ok: proceso finalizado", "InfoIcon", `Proceso terminado: ${message}`, "success", 4000, "bottom-right" )
				this.$root.$emit("bv::hide::modal", 'modal-edit-budget')
				this.setSelectedYearBudgetCondominium( year ) // seteo el nuevo año seleccionado
				this.$emit('update-data', year )
			}

			this.isUpdatingBudget = false
        }
    }
}
</script>

<style scoped>
.btn-modal{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}


.gray-text{
  color: rgb(170, 170, 170);
}
</style>