var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalCHsingle",attrs:{"id":"modal-quotas-condo-housing-charge","title":'Cargar Cuota a vivienda: '+ (_vm.selectedHousing ? _vm.selectedHousing.housingnumber: ''),"ok-only":"","hide-footer":"","no-close-on-backdrop":""},on:{"hide":_vm.modalWasClose}},[_c('b-overlay',{attrs:{"show":_vm.isSavingCuota}},[_c('ValidationObserver',{ref:"modalQuotasCharge",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handlerQuotaCharge($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Monto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Monto"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"0.00"},on:{"keypress":_vm.validateNumberWithDecimal},model:{value:(_vm.chargeCuota.amount),callback:function ($$v) {_vm.$set(_vm.chargeCuota, "amount", $$v)},expression:"chargeCuota.amount"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Fecha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Fecha"}},[_c('b-form-input',{attrs:{"type":"date","state":errors[0] ? false : valid ? true : null,"min":_vm.minMaxDateFromYear.firstDay,"max":_vm.minMaxDateFromYear.lastDay},model:{value:(_vm.chargeCuota.operationDate),callback:function ($$v) {_vm.$set(_vm.chargeCuota, "operationDate", $$v)},expression:"chargeCuota.operationDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"concepto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Concepto"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.chargeCuota.movementType),callback:function ($$v) {_vm.$set(_vm.chargeCuota, "movementType", $$v)},expression:"chargeCuota.movementType"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.concepts),function(concept){return _c('option',{key:concept.value,domProps:{"value":concept.value}},[_vm._v(" "+_vm._s(concept.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),(_vm.requireSpecifyMovement)?_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"tipo de cargo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Tipo de cargo"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.chargeCuota.chargeType),callback:function ($$v) {_vm.$set(_vm.chargeCuota, "chargeType", $$v)},expression:"chargeCuota.chargeType"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.movementTypes),function(movement){return _c('option',{key:movement.value,domProps:{"value":movement.value}},[_vm._v(" "+_vm._s(movement.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Notas"}},[_c('b-form-group',{attrs:{"label":"Para agregar notas"}},[_c('b-form-textarea',{attrs:{"placeholder":"Escribe la nota","maxlength":100,"rows":"2"},model:{value:(_vm.chargeCuota.notes),callback:function ($$v) {_vm.$set(_vm.chargeCuota, "notes", $$v)},expression:"chargeCuota.notes"}})],1)],1)],1)],1),_c('div',{staticClass:"btn-modal"},[_c('b-button',{attrs:{"type":"submit","disabled":invalid,"variant":"primary"}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }