<template>
    <b-overlay class="mt-1" :show="isLoadingBudgetsByPeriods" >           
        <b-table
            :fields="fieldsFees"
            :items="condominalBudgetByPeriods"
            class="mb-0 "
            show-empty
            empty-text="No hay registros de periodos a mostrar" 
            :thead-class="!condominalBudgetByPeriods.length ? 'd-none' :''"        

        >

            <template #head(isMarked)>                
                <b-form-group>
                    <b-form-checkbox
                        v-model="isMarkedAll"      
                        @change="setMarkedAll"                                                   
                    >                                                           
                    </b-form-checkbox>
                </b-form-group>
            </template>

            <template #cell(isMarked)="row">                    
                <b-form-checkbox
                    v-model="row.item.isMarked"      
                    @change="setMarked(row.item)"                                                   
                >                                
                </b-form-checkbox>                
            </template>  

            <template #cell(period)="row"  >                    
                <b-link class="edit-h capitalize-text"  @click="toggleDetails(row.item)">
                    <span  > {{ row.item.period }}  </span>                    
                    <feather-icon :icon="!row.item.detailsShowing ? 'EyeIcon': 'EyeOffIcon' " size="16"/>                     
                </b-link>                                                           
            </template> 

            <template #row-details="row">
                <b-overlay :show="row.item.isLoadingDetails" >
                    <b-table
                        :items="row.item.detailsPeriod"
                        :fields="fieldsDetailFee"           
                        primary-key="id"            
                        show-empty
                        empty-text="No se tiene registros en el detalle"
                        responsive  
                        no-headers                         
                        :thead-class="!row.item.detailsPeriod.length ? 'd-none' :''"        
                    >
                    
                        <template #cell(paymentdate)="row">                    
                            <span>{{ row.item.paymentdate ? row.item.paymentdate : 'N/A' }}</span>                   
                        </template>

                        <template #cell(paymentmethod)="row">                    
                            <span>{{ row.item.paymentmethod ? row.item.paymentdate : 'N/A' }}</span>                   
                        </template>

                    </b-table>
                </b-overlay>                
            </template> 

       

        </b-table>
    </b-overlay>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import Ripple from 'vue-ripple-directive'
import { showAlertMessage, toCurrency, sweetAlert } from '@/helpers/helpers'
import { seedBudgetCondosDetail } from '@/data/fivesClub'

export default {
    directives: {Ripple, },
    props:{
        isLoadingBudgetsByPeriods:{
            type: Boolean,
            required: true,
        }     
    },
    data() {
        return {
            selectedYear: new Date().getFullYear(),
            fieldsFees: [   
                { key: 'isMarked', label: '--', class: 'text-center', thStyle: { width: "50px" }  },
                { 
                    key: 'period', label:'Periodo', class:'text-justify', 
                    thStyle: { width: "280px" }
                },
                {   key:'feeAmount', label:'Cuota Condominal',
                    formatter: (value, key, item) => {return this.formatMoney({value, item}) }
                },               
                {   key:'balanceFee', label:'Saldo',
                    formatter: (value, key, item) => {return this.formatMoney({value, item}) }                    
                },                
                
            ],
            fieldsDetailFee:[
                {
                    key:'paymentdate', label:'Fecha de pago'
                },
                {
                    key:'paymentmethod', label:'Forma de pago'
                },
                {
                    key:'userName', label:'Usuario'
                },
                {
                    key:'subtype', label:'Movimiento'
                },
                {
                    key:'quantity', label:'Monto'
                },
                {
                    key:'notes', label:' Notas'

                }
            ], 
            detailsPeriod: seedBudgetCondosDetail           
        }
    },
    computed:{
        ...mapState('start', ['hotels']), 
        ...mapState('fivesClubCatalogs', ['condominalBudgetByPeriods','isMarkedAllBudgetByPeriods']),           

        isMarkedAll: {
            get(){
                return this.isMarkedAllBudgetByPeriods
            },
            set(isMarked) {                
                this.setIsMarkedAllBudgetByPeriods(isMarked)
            }
        }        
    },
    methods:{        
        ...mapActions('fivesClubCatalogs', ['fetchDetailBudgetPeriod','fetchAnnualsCondominalsBudgets']),
        ...mapMutations('fivesClubCatalogs',['setBudgetByPeriodSelected','setIsLoadingDetailsBudgetByPeriod','setDetailsBudgetByPeriod','setIsMarkedAllBudgetByPeriods','setMarkedBudgetByPeriod','setAllMarkedBudgetByPeriods']),
        
        setMarkedAll(isMarked){                       
            this.setIsMarkedAllBudgetByPeriods(isMarked)              
            this.setAllMarkedBudgetByPeriods({isMarked})
        },
        setMarked(item){
            const {id, isMarked } = item
            this.setMarkedBudgetByPeriod({id,isMarked})            
        },
        setYearSelected(year){
            if(year){                
                this.$emit('set-annual-budget-selected', year)
            }
        },
        formatMoney(data){
            const {value, item} = data
            return toCurrency(value, item?.currency?.code) 
        },
        async toggleDetails(item){

            this.setBudgetByPeriodSelected({id: item.uuid, boolean: !item._showDetails})   

            this.condominalBudgetByPeriods.forEach( period => {
                if (period.uuid !== item.uuid && period._showDetails) {
                    this.$set(period, "_showDetails", !period._showDetails);
                }
            })

            this.$set(item, "_showDetails", !item._showDetails) // abro el colapse details      
            
            if( !item.detailsPeriod.length && item._showDetails){
                const idPeriod = item.uuid          
                this.setIsLoadingDetailsBudgetByPeriod({id: idPeriod, boolean: true}) // ejecuto el loader en true                    
                setTimeout(() => {
                    // TODO pedir a back detalle del period
                    this.setDetailsBudgetByPeriod({ id: idPeriod, details:this.detailsPeriod })
                    this.setIsLoadingDetailsBudgetByPeriod({id: idPeriod, boolean: false}) // ejecuto el loader en true                    
                }, 3000);
            }
        }
    }
}
</script>

<style scoped>

.capitalize-text{
  text-transform: capitalize;
}

.edit-h{
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

</style>