<template>
	<b-modal size="sm"
		id="modal-condominium-budget-create"
		title="Agregar Presupuesto Condominal"
		ok-only hide-footer no-close-on-backdrop
		ref="modalPCond"
		@hide="modalWasClose"
	>		
		<b-overlay :show="isSavingCondominalBudget">		
			<ValidationObserver ref="modalCondominiumBudget" v-slot="{ invalid }">
				<b-form @submit.prevent="handlerCondominiumBudget">
					<b-row>								
						<b-col md="12">
							<ValidationProvider rules="required" name="año">
								<b-form-group label="Año" slot-scope="{ valid, errors }">								
									<b-form-select :state="errors[0] ? false : valid ? true : null" v-model="condominalBudget.year" >
										<option :value="null">Seleccione un año</option>                                        
										<option 
											v-for="year in years"
											:key="year"
											:value="year"
											:disabled="yearsFromBudget.includes(year) "
											:class="yearsFromBudget.includes(year) ? 'gray-text':''"
										> 
											Cuotas de {{ year }}
										</option>
									</b-form-select>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>					                
						</b-col>

						<b-col md="12">						
							<ValidationProvider rules="required" name="Cuota Condominal">
								<b-form-group label="Cuota Condominal" slot-scope="{ valid, errors }">
									<b-form-input
										class="form-control"
										type="number"
										@keypress="onlyNumberWithDecimal"
										v-model="condominalBudget.amountBudget"
										placeholder="Ingrese la cuota condominal"									
										:state="errors[0] ? false : valid ? true : null"
									/>
									<b-form-invalid-feedback v-for="error in errors" :key="error">
										{{ error }} <br class="p-0">
									</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>					                
						</b-col>

						<b-col md="12">
							<ValidationProvider rules="required" name="Periodicidad">
								<b-form-group label="Periodicidad" slot-scope="{ valid, errors }">
									<b-form-select
										v-model="condominalBudget.periodicity"									
										:state="errors[0] ? false : valid ? true : null"
									>
									<option :value="null">Seleccione una periodicidad</option>
									<option v-for="period in periodicityOptions" :key="period.id" :value="period.id">{{ period.name }}</option>
									</b-form-select>
									<b-form-invalid-feedback>
										{{ errors[0] }}
									</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<b-col md="12">
							<ValidationProvider rules="required" name="Divisa">
								<b-form-group label="Divisa" slot-scope="{ valid, errors }" >
									<b-form-select
										v-model="condominalBudget.currency"									
										:state="errors[0] ? false : valid ? true : null"
									>									
										<option :value="null">Seleccione una divisa</option>
                    					<option :value="1">USD</option>
                    					<option	:value="2">MXN</option>
									</b-form-select>
									<b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>
						
					</b-row>
					
					<div class="btn-modal">
						<b-button variant="warning" @click="resetForm">Reset</b-button>
						<b-button type="submit" :disabled="invalid" variant="primary"> Guardar </b-button>
					</div>

				</b-form>
			</ValidationObserver> 		
		</b-overlay>
	</b-modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { showAlertMessage } from "@/helpers/helpers";
import { utils } from "@/modules/fivesClub/mixins/utils";
import { condominalFeePeriodicity } from '@/data/fivesClub';


export default {
	mixins: [utils],
	props:{              
		
		
    },
	data(){
		return {
			condominalBudget: {
				year: null,
				amountBudget: null,				
				periodicity: null,		
				currency: null,						
			},			
			isSavingCondominalBudget: false,
			periodicityOptions: condominalFeePeriodicity,						
		}
	},
	
	computed: {
        ...mapState('fivesClubCatalogs', ['listAnnualCondominiumBudgets']),       
		...mapState('auth', ['user']),	
		//un añoo aras y dos adelante
		years(){
			const initYear = new Date().getFullYear() - 1
			const years = []

			for (let i = 0; i < 3; i++) {
                years.push( initYear + i)
            }
			return years
		},
		// del listado de presupuestos obtengo los años para usar en el listado de años del modal,
		// para validar el disabled en los options list
		yearsFromBudget(){
			return this.listAnnualCondominiumBudgets.map( condF => condF.year )
		}
    },
	methods:{
		
		...mapActions('fivesClubCatalogs', ['saveCondominalFee','fetchCondoFeeHousingDetail']),	
        ...mapMutations('fivesClubCatalogs',['setCondominiumFeeSelected']), 		
        ...mapMutations('fivesClubCatalogs',['setIsLoadingDetailsCondominiumFee', 'setDetailsCondominiumFee','setSelectedYearBudgetCondominium','setSelectedBudgetCondominium','setCondominalFeesHousingByYear','setFeeHousingDetail','setFilterSearchCondominalFeesHousingByYear']), 
		
		async handlerCondominiumBudget(){
			this.isSavingCondominalBudget = true
            let processSuccess = false
			const payload  = {
				idCondo: parseInt(this.$route.params.id), //desde la ruta obtengo un id
        		year: this.condominalBudget.year,
        		amountBudget: this.condominalBudget.amountBudget,
        		currency: this.condominalBudget.currency,
				periodicity: this.condominalBudget.periodicity,        		
				createdBy: this.user.idUser,				 
			}	
			
			const { status, message, idFee } = await this.saveCondominalFee(payload)

			processSuccess = status

			const year = JSON.parse( JSON.stringify( this.condominalBudget.year ) ) // lo necesito, para ocupar despues

            if ( status ) {
				             
				this.$root.$emit("bv::hide::modal", 'modal-condominium-budget-create')// cierro modal
                // showAlertMessage("Ok: proceso finalizado", "InfoIcon", `Proceso terminado: ${message}`, "success", 4000, "bottom-right" )
				Object.assign(this.$data, this.$options.data());
                this.$refs.modalCondominiumBudget.reset(); //reset form  

				this.setSelectedBudgetCondominium(null)
                this.setSelectedYearBudgetCondominium( null )
                this.setCondominalFeesHousingByYear([]) // limpio los presupuestos por año / viviendas
                this.setFeeHousingDetail(null)
                this.setFilterSearchCondominalFeesHousingByYear('')
            }

			if( !status ){
                showAlertMessage("Sucedió un error", "InfoIcon", `Ocurrió un error: ${message}`, "danger", 4000, "bottom-right" )
			}
			
			if( processSuccess ){
				
				this.$emit('update-data', {year, idBudget: idFee})										
			}
			this.isSavingCondominalBudget = false
			
		},
		updateData(){
		},
		resetForm(){
			Object.assign(this.$data, this.$options.data());			              			
			this.$refs.modalCondominiumBudget.reset(); //reset form               

		},
		async getDetailRow(item){
            const idHousingFee = item.id
            this.setIsLoadingDetailsCondominiumFee({id: idHousingFee, boolean: true}) // ejecuto el loader en true
            const { status, data } = await this.fetchCondoFeeHousingDetail( idHousingFee )    // pide detalle al back   
            if( status && data.length > 0 ) {    
                this.setDetailsCondominiumFee({ id: idHousingFee, details:data })
            }         
            this.setIsLoadingDetailsCondominiumFee({id: idHousingFee, boolean: false}) // ejecuto el loader en true
        },
		modalWasClose(){			
            // this.setCondominiumFeeSelected({id: this.selectedFeeHousing.id, boolean: false})       
			Object.assign(this.$data, this.$options.data());			              
		}
	}
}
</script>

<style scoped>
.btn-modal{
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.gray-text{
  color: rgb(170, 170, 170);
}
</style>