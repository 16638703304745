var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.condominiumDataEdit.mainData)?_c('ValidationObserver',{ref:"updateCondominiumForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.update($event)}}},[_c('div',{staticClass:"border rounded mb-1"},[_c('b-row',{staticClass:"p-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Información General")])]),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nombre"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese el Nombre"},model:{value:(_vm.condominiumDataEdit.mainData.name),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "name", $$v)},expression:"condominiumDataEdit.mainData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Hotel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Resort"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.condominiumDataEdit.mainData.resorts),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "resorts", $$v)},expression:"condominiumDataEdit.mainData.resorts"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione hotel")]),_vm._l((_vm.hotels),function(hotel){return _c('option',{key:hotel.id,domProps:{"value":hotel.id}},[_vm._v(" "+_vm._s(hotel.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Dirección"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese la Dirección"},model:{value:(_vm.condominiumDataEdit.mainData.address),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "address", $$v)},expression:"condominiumDataEdit.mainData.address"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Código postal"}},[_c('b-form-input',{attrs:{"type":"number","state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese el Código postal"},model:{value:(_vm.condominiumDataEdit.mainData.zip),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "zip", $$v)},expression:"condominiumDataEdit.mainData.zip"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1)],1),_c('div',{staticClass:"border rounded mb-1"},[_c('b-row',{staticClass:"p-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Información Bancaria")])]),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"bankName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Banco"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese la Cuenta bancaria"},model:{value:(_vm.condominiumDataEdit.mainData.bankName),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "bankName", $$v)},expression:"condominiumDataEdit.mainData.bankName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"bankAccount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Cuenta bancaria"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese la Cuenta bancaria"},model:{value:(_vm.condominiumDataEdit.mainData.bankAccount),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "bankAccount", $$v)},expression:"condominiumDataEdit.mainData.bankAccount"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"swift"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Swift"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese Swift"},model:{value:(_vm.condominiumDataEdit.mainData.swift),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "swift", $$v)},expression:"condominiumDataEdit.mainData.swift"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"bankAdress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Domicilio del Banco"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese la Cuenta bancaria"},model:{value:(_vm.condominiumDataEdit.mainData.bankAdress),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "bankAdress", $$v)},expression:"condominiumDataEdit.mainData.bankAdress"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"float-left pt-2 mt-1"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":true},model:{value:(_vm.condominiumDataEdit.mainData.status),callback:function ($$v) {_vm.$set(_vm.condominiumDataEdit.mainData, "status", $$v)},expression:"condominiumDataEdit.mainData.status"}},[_vm._v(" Activado ")])],1)])],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"btn-block",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.isUpdatingCondominium}},[(_vm.isUpdatingCondominium)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Actualizar ")],1)],1)])],1)],1)]}}],null,false,1248342751)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }