<template>
  <div>


    <b-row class="mt-1 mb-1 p-1">
      <b-col>
        <!-- <h4>Presupuesto Condominal</h4> -->
        <b-table
          fixed
          small
          :fields="selectedCondos.id == 1 ? fieldsCurrency : fields"
          :items="condominiumFees"
          class="mb-0 text-center"
          show-empty
          empty-text="No hay registros para mostrar"
          sticky-header="20rem"
          :busy.sync="loadingFees"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"/><br>
              <strong>Cargando Presupuesto Condominal</strong>
            </div>
          </template>
          <template #cell(condoFee)="row">
            <div class="margin-half text-center p-0 action-class condo-fee-input" v-if="isEditingCondoFee(row.item.id)">
              <ValidationObserver :ref="'condominalFee'+row.item.id">
                <ValidationProvider rules="required" :name="`Cuota del año ${row.item.yearCondoFee}`">
                  <b-form-group slot-scope="{ valid, errors }" class="m-0">
                    <b-form-input
                      type="number"
                      size="sm"
                      @keypress="onlyNumberWithDecimal"
                      v-model="row.item.condoFeeEdited"
                      :disabled="isUpdatingCondominalFees || isUpdatingCondoFee(row.item.id)"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </ValidationObserver>
            </div>
            <div class="margin-half text-center p-0" v-else>
              <strong>{{ formatMoney(row.item.condoFee) }}</strong>
            </div>
          </template>

          <template #cell(condoFeeCurrency)="row">
            <div class=" action-class condo-currency-input" v-if="isEditingCondoFee(row.item.id)">
              <ValidationObserver :ref="'condominalFeeCurrency'+row.item.id">
                <ValidationProvider rules="required" :name="`Divisa del año ${row.item.yearCondoFee}`">
                  <b-form-group slot-scope="{ valid, errors }" class="m-0">
                  <b-form-select
                    v-if="!isUpdatingCondominalFees"
                    v-model="row.item.condoFeeCurrencyEdited"
                    :disabled="isUpdatingCondominalFees || isUpdatingCondoFee(row.item.id)"
                    :state="errors[0] ? false : valid ? true : null"
                    size="sm"
                  >
                    <option :value="null">Seleccione una divisa</option>
                    <option :value="1">USD</option>
                    <option :value="2">MXN</option>
                  </b-form-select>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </ValidationObserver>
            </div>
            <div class="margin-half text-center p-0" v-else>
              <strong>{{ row.item.condoFeeCurrency ? row.item.condoFeeCurrency == 2 ? 'MXN' : 'USD' : 'N/A' }}</strong>
            </div>
          </template>

          <template #cell(periodicity)="row">
            <div class=" action-class condo-currency-input" v-if="isEditingCondoFee(row.item.id)">
              <ValidationObserver :ref="'condominalPeriodicity'+row.item.id">
                <ValidationProvider rules="required" :name="`Periodicidad del año ${row.item.yearCondoFee}`">
                  <b-form-group slot-scope="{ valid, errors }" class="m-0">
                  <b-form-select
                    v-model="row.item.periodicityCode"
                    :disabled="isUpdatingCondominalFees"
                    :state="errors[0] ? false : valid ? true : null"
                  >
                    <option :value="null" disabled>Seleccione una periodicidad</option>
                    <option v-for="period in periodicityOptions" :key="period.id" :value="period.id">{{ period.name }}</option>
                  </b-form-select>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </ValidationObserver>
            </div>
            <div class="margin-half text-center p-0" v-else>
              <strong>{{ row.item.periodicity || 'N/A' }}</strong>
            </div>
          </template>

          <template #cell(actions)="row">
            <div v-if="can('fivesclub_housing_show_condos_update_button_action_condo_fee_edit')">
              <b-button
                v-if="isEditingCondoFee(row.item.id)"
                size="sm"
                variant="warning"
                class="margin-half m-0"
                :disabled="isUpdatingCondoFee(row.item.id)"
                @click="cancelEdition(row.item)"
              >
                <feather-icon icon="XIcon" size="12" />
              </b-button>
              <b-button
                v-if="canbeDeleted(row.item.yearCondoFee) && !isEditingCondoFee(row.item.id)"
                size="sm"
                variant="danger"
                class="margin-half m-0"
                :disabled="isUpdatingCondoFee(row.item.id)"
                @click="deleteFee(row.item)"
              >
                <feather-icon icon="Trash2Icon" size="12" />
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                class="margin-half m-0"
                :disabled="isUpdatingCondoFee(row.item.id)"
                @click="isEditingCondoFee(row.item.id) ? updateCondoFee(row.item) : editCondoFee(row.item)"
              >
                <b-spinner class="align-middle" small v-if="isUpdatingCondoFee(row.item.id)"/>
                <feather-icon :icon="isEditingCondoFee(row.item.id) ? 'SaveIcon' : 'Edit2Icon'" size="12" v-else/>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { showAlertMessage, toCurrency, sweetAlert } from '@/helpers/helpers'
import { condominalFeePeriodicity } from '@/data/fivesClub'
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [utils, acl],
  props:{
    condominiumFees:{
      type: Array,
      required: true,
    },
    selectedCondos: {
      type: Object,
      required: true,
    },
    yearsList:{
      type: Array,
      required: true,
    },
    isLoadingFees: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCreatingNewCondoFee: false,
      isUpdatingCondominalFees: false,
      isEditing: false,
      isUpdating: false,
      editedFee: null,
      periodicityOptions: condominalFeePeriodicity,
      fieldsCurrency: [
        {key:'yearCondoFee', label:'Año', class: 'text-center'},
        {key:'condoFee', label:'Cuota Condominal', class: 'text-center', formatter: value => {
          return this.formatMoney(value)
        }},
        {key:'periodicity', label:'Periodicidad'},
        {key:'condoFeeCurrency', label:'Divisa'},
        {key:'actions', label:'Acciones'}
      ],
      fields: [
        {key:'yearCondoFee', label:'Año', class: 'text-center'},
        {key:'condoFee', label:'Cuota Condominal', class: 'text-center', formatter: value => {
          return this.formatMoney(value)
        }},
        {key:'periodicity', label:'Periodicidad'},
        {key:'actions', label:'Acciones'}
      ],
      condominalFee: {
        condos: null,
        yearCondoFee: '',
        condoFee: '',
        condoFeeCurrency: null,
        periodicityCode: null,
        createdBy: null
      },
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    loadingFees(){
      return this.isUpdatingCondominalFees || this.isLoadingFees
    }
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['saveCondominalFee','updateCondominalFee','fetchAnnualsCondominalsBudgets']),
    ...mapMutations('fivesClubCatalogs',['setCondoFees']),
    editCondoFee(item){
      item.condoFeeEdited = structuredClone(item.condoFee)
      item.condoFeeCurrencyEdited = structuredClone(item.condoFeeCurrency)
      item.periodicityCode = structuredClone(item.periodicity)
      this.isEditing = true
      this.editedFee = item.id
    },
    cancelEdition(item){
      item.condoFeeEdited = structuredClone(item.condoFee)
      item.condoFeeCurrencyEdited = structuredClone(item.condoFeeCurrency)
      item.periodicityCode = structuredClone(item.periodicity)
      this.isEditing = false
      this.editedFee = null
    },
    reset(){
      this.condominalFee = {
        condos: null,
        yearCondoFee: '',
        condoFee: '',
        condoFeeCurrency: null,
        periodicityCode: null,
        createdBy: null
      }
    },
    async addCondominalFee(){
      this.isCreatingNewCondoFee = true

      this.condominalFee.condos = this.selectedCondos.id
      this.condominalFee.createdBy = this.user.idUser

      const response = await this.saveCondominalFee(this.condominalFee)
      if (response) {
        await this.manageSuccesfullFeeRequest()
        showAlertMessage( 'Se añadió la cuota condominal', 'InfoIcon', 'Se ha añadido la cuota del año', 'success', 4000, 'bottom-right')
      } else showAlertMessage( 'No se actualizó la cuota condominal', 'InfoIcon', 'Hubo un error al actualizar la cuota, intentelo más tarde', 'warning', 4000, 'bottom-right')

      this.isCreatingNewCondoFee = false
    },
    async saveCondoFee(item){
      this.isUpdating = true
      let valid
      const response = await this.updateCondominalFee(item)
      if (response) {
        this.isUpdatingCondominalFees = true
        await this.manageSuccesfullFeeRequest()
        this.editedFee = null
        valid = true
        this.isUpdatingCondominalFees = false
      } else valid = false
      this.isUpdating = false
      return valid
    },
    async updateCondoFee(item){
      const swalert = {
        title: "Modificando cuota condominal de " + item.yearCondoFee,
        message: "Las cuotas por vivienda de este año relacionadas a este condominio se verán afectadas por este cambio. ¿Seguro que desea modificar esta cuota?",
        icon: "warning",
        confirmButton: "Modificar",
        cancelButton: "Cancelar",
      }

      const {isConfirmed} = await sweetAlert(swalert)

      if ( isConfirmed ) {
        const modified = await this.saveCondoFee(item)
        if (modified) showAlertMessage( 'Cuota condominal actualizada', 'InfoIcon', 'Se han guardado los cambios.', 'success', 4000, 'bottom-right')
        else showAlertMessage( 'No se actualizó la cuota condominal', 'InfoIcon', 'Hubo un error al actualizar la cuota, intentelo más tarde', 'warning', 4000, 'bottom-right')
      }
    },
    async manageSuccesfullFeeRequest(){
      const respuesta = await this.fetchAnnualsCondominalsBudgets(this.selectedCondos.id)
      const sorteredArray = respuesta?.sort(function(a, b){return a?.yearCondoFee - b?.yearCondoFee}) || []
      this.$emit('set-condo-fees', sorteredArray)
      this.$emit('set-condominal-fees-years-list', sorteredArray)
      this.reset()
    },
    async deleteFee(item){
      if (new Date().getFullYear() < item.yearCondoFee){
        const swalert = {
          title: "Eliminando cuota condominal de " + item.yearCondoFee,
          message: "Las cuotas por vivienda de este año también se eliminarán. ¿Seguro que desea eliminar esta cuota?",
          icon: "danger",
          confirmButton: "Eliminar",
          cancelButton: "Cancelar",
        }

        const {isConfirmed} = await sweetAlert(swalert)

        if ( isConfirmed ) {
          this.isUpdatingCondominalFees = true
          this.editedFee == item.id
          item.delete = true
          const modified = await this.saveCondoFee(item)
          if (modified) showAlertMessage( 'Cuota condominal eliminada', 'InfoIcon', 'Se han guardado los cambios.', 'success', 4000, 'bottom-right')
          this.isUpdatingCondominalFees = false
        }
      } else return showAlertMessage( 'No se puede borrar esta cuota', 'InfoIcon', 'Solo puede borrar cuotas de años siguientes al actual', 'warning', 4000, 'bottom-right')
    },
    isEditingCondoFee(id){
      return this.isEditing && this.editedFee == id
    },
    isUpdatingCondoFee(id){
      return this.isUpdating && this.editedFee == id
    },
    canbeDeleted(year){
      return new Date().getFullYear() < year
    },
    formatMoney(amount){
      return toCurrency(amount)
    }
  }
}
</script>

<style scoped>
.margin-half{
  margin: 0.5rem !important;
}
.margin-side-half{
  margin: 0 0.25rem !important;

}
.condo-fee-input{
  max-width: 9.3rem !important;
  align-self: center;
  /* margin-right: 0; */
}
.condo-currency-input{
  max-width: 6rem !important;
  align-self: center;
  /* margin-right: 0; */
}
.condo-fee-card{
  /* max-width: 25rem; */
  align-items: center;
}
.gray-text{
  color: rgb(170, 170, 170);
}
.action-class{
  display: inline-table;
}
</style>