<template>
	<div class="my-pagination">
		<b-pagination
			v-model="paginaActual"
			:total-rows="totalRows"
			:per-page="perPage"
			first-number
			last-number
			class="mb-0 mt-1 mt-sm-0"
			prev-class="prev-item"
			next-class="next-item"
			@input="changePage"
			>
			<template #prev-text>
				<feather-icon
				icon="ChevronLeftIcon"
				size="18"
				/>
			</template>
			<template #next-text>
				<feather-icon
				icon="ChevronRightIcon"
				size="18"
				/>
			</template>
		</b-pagination>
	</div>
</template>

<script>

export default {  
  props:{
    currentPage:{
      type: Number,
      required: true,
    },
    totalRows:{
      type: Number,
      required: true,
    },
    perPage:{
      type: Number,
      required: true,
    },
  },
  data() {
    return {                  
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      refMembershipsListTable : null, 
      paginaActual: this.currentPage,
                       
    }
  },
  computed: {    
    dataForPaginator(){                 
      return {
          from: this.perPage * (this.paginaActual - 1) + (this.totalRows ? 1 : 0),
          to: this.perPage * (this.paginaActual - 1) + this.totalRows,
          of: this.totalRows,
      }
    },
    
  },
  methods:{
    changePage(page){
      this.$emit('change-page', page)  
      this.paginaActual =  page     
    }
  }
}
</script>

<style>
.my-pagination{
	display: flex;
	justify-content: flex-end;
}
</style>