<template>
    <div>
        <HousingNav/>
        <b-overlay :show="isLoadingCondominiumData">
            <b-card>
                <div class="float-right">
                    <b-button
                        :to="{ name: 'condominiums'}"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                    >
                        <feather-icon icon="ArrowLeftIcon" style="color: white" />
                    </b-button>
                </div>

                <b-tabs>
                    <b-tab active>
                        <template #title><span>General</span></template>
                        <CondominiumFormEdit
                            :condominiumDataEdit="condominiumDataEdit"
                            :isLoadingCondominiumData="isLoadingCondominiumData"
                            :isUpdatingCondominium="isUpdatingCondominium"
                            @update-condominium="update"
                            v-if="condominiumDataEdit"
                        />
                    </b-tab>
                    <b-tab @click="clickBudgetTab" >
                        <template #title><span>Presupuesto</span></template>

                        <CondominiumBudgetYear
                            :condominiumDataEdit="condominiumDataEdit"
                            :isLoadingBudgetsByPeriods="isLoadingBudgetsByPeriods"
                            :isLoadingAnnualFeesHousing="isLoadingAnnualFeesHousing"
                            :isDeletingBudget="isDeletingBudget"                            
                            :isCreatingBudget="isCreatingBudget"
                            :isUpdatingBudget="isUpdatingBudget"
                            :isExplodingData="isExplodingData"
                            @set-is-deleting-budget="setIsDeletingBudget"
                            @open-modal-add-budget="openModalAddBudget"
                            @set-selected-budget="setSelectedBudget"
                            @get-condominal-budget-by-year="getFeesBudgetSelectedYear"
                            @get-annuals-condominal-budgets="getAnnualsCondominalBudgets"
                            @get-fees-housing-by-year="getFeesHousingByYear"
                            @update-data="updateData"
                            @get-info-tab="clickBudgetTab"
                        />

                        <b-alert show variant="info" class="p-1 text-center" v-if="isExplodingData">
                            Se está procesando información, cuando termine puede darle en el botón de refrescar para actualizar.                                                        
                            <div class="loader">
                                <span></span>
                                <span></span>
                                <span></span>                                
                            </div>
                        </b-alert>

                        <CondominiumFeesByYear
                            :budget="selectedBudget"
                            :paymentTypes="paymentTypes"
                            :isLoadingAnnualFeesHousing="isLoadingAnnualFeesHousing"
                            :isLoadingAnnualFeesHousingDetail="isLoadingAnnualFeesHousingDetail"
                            :isLoadingExcelReport="isLoadingExcelReport"
                            :isLoadingExcelDetailsReport="isLoadingExcelDetailsReport"
                            @get-fees-housing-by-year="getFeesHousingByYear"
                            @get-annuals-condominal-budgets-report="getAnnualsCondominalBudgetsReport"
                            @get-condominal-fees-details-report="getCondominalFeesDetailsReport"
                            @set-is-loading-annual-fees-housing="setIisLoadingAnnualFeesHousing"
                        />

                        <CondominiumModalCreateBudget
                            :budget="selectedBudget"
                            @get-annuals-condominal-budgets="getAnnualsCondominalBudgets"
                            @set-is-creating-budget="setIsCreatingBudget"
                            @set-is-updating-budget="setIsUpdatingBudget"
                            @get-condominal-budget-by-year="getFeesBudgetSelectedYear"
                            @update-data="updateData"
                        />

                    </b-tab>
                </b-tabs>

            </b-card>
        </b-overlay>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex"
    import Ripple from 'vue-ripple-directive'

    import CondominiumFormEdit from "@/modules/fivesClub/components/catalogs/housing/CondominiumFormEdit"
    import CondominiumBudget from "@/modules/fivesClub/components/catalogs/housing/CondominiumBudget"
    import CondominiumBudgetYear from "@/modules/fivesClub/components/catalogs/housing/CondominiumBudgetYear"
    import CondominiumFeesByYear from "@/modules/fivesClub/components/catalogs/housing/CondominiumFeesByYear"

    import CondominiumBudgetDetailByPeriods from "@/modules/fivesClub/components/catalogs/housing/CondominiumBudgetDetailByPeriods"

    import CondominiumModalCreateBudget from "@/modules/fivesClub/components/catalogs/housing/CondominiumModalCreateBudget"

    import HousingNav from "@/modules/fivesClub/components/catalogs/housing/HousingNav"
    import { showAlertMessage, stringAleatorio } from "@/helpers/helpers";


    export default {
        directives: {Ripple, },
        components: {
            HousingNav,
            CondominiumFormEdit,
            CondominiumBudget,
            CondominiumBudgetYear,
            CondominiumFeesByYear,
            CondominiumBudgetDetailByPeriods,
            CondominiumModalCreateBudget
        },
        async created() {
            await this.init()
        },
        data() {
            return {
                isLoadingCondominiumData: false,
                isLoadingAnnualsCondominalBudgets: false, // boolean para el listado de cuotas condominales
                isLoadingBudgetsByPeriods: false, //
                isLoadingAnnualFeesHousing: false, // para loader en tabla de presupuesto anuales por viviendas
                isLoadingAnnualFeesHousingDetail: false, // para loader en tabla de presupuesto anuales por detalle de vivienda
                isUpdatingCondominium: false,
                currentYear: new Date().getFullYear(),
                selectedBudget: null,
                paymentTypes: [],
                isUpdatingBudget: false,
                isCreatingBudget: false,
                isDeletingBudget: false,
                isLoadingExcelReport: false,
                isLoadingExcelDetailsReport: false,
                isExplodingData: false
            }
        },
        computed: {
            ...mapState('start', ['hotels']),
            ...mapState('auth', ['user']),
            ...mapState('fivesClubCatalogs', ['condominalFeesHousingByYear','condominiumDataEdit','listAnnualCondominiumBudgets']),
        },
        methods: {
            ...mapActions('fivesClubCatalogs', ['fetchCondosById','updateCondos','getInitialContentEditCondominium','fetchAnnualsCondominalsBudgets','fetchCondominalBudgetByPeriods','fetchCondominalBudgetFeesByYear','fetchCondominalBudgetFeesByYearReport', 'fetchCondominalFeesDetailsReport', 'fetchPaymentMethodsFeeQuota','calcCondoFeeHousing']),
            ...mapMutations('fivesClubCatalogs',['setCondominalBudgetByPeriods','setCondominalFeesHousingByYear','setFeeHousingDetail','setFilterSearchCondominalFeesHousingByYear','setCondominiumDataToEdit','setListAnnualCondominiumBudgets','setSelectedYearBudgetCondominium','setSelectedBudgetCondominium']),
            ...mapMutations('start',['setHotels']),
            async init(){
                const idCondo = this.$route.params.id
                //limmpio todo lo que hay presupuestos
                this.setListAnnualCondominiumBudgets([]) // limpio listado de presupuestos
                this.setCondominalBudgetByPeriods([]) // limpio los presupuestos por periodos
                this.setSelectedBudgetCondominium(null)
                this.setSelectedYearBudgetCondominium( null )
                this.setCondominalFeesHousingByYear([]) // limpio los presupuestos por año / viviendas
                this.setFeeHousingDetail(null)
                this.setFilterSearchCondominalFeesHousingByYear('')
                // limpio state, por si tiene valores
                

                if( this.isANumber(idCondo) ){
				    this.isLoadingCondominiumData = true
                    const condo = await this.fetchCondosById({idCondo})
                    this.paymentTypes = await this.fetchPaymentMethodsFeeQuota()
                    this.setCondominiumDataToEdit( condo[0] ) // state para information general
				    this.isLoadingCondominiumData = false
                    // sino tengo hoteles, los pido, necearios para mostar en el select input resort, en informacion general
                    if( this.hotels.length === 0 ){
                        const { hotels } = await this.getInitialContentEditCondominium()
                        this.setHotels(hotels)
                    }
                    // obtengo la lista de presupuestos anuales
                    await this.getAnnualsCondominalBudgets()
                    
                }
            } ,
            async clickBudgetTab(){

                const totalListBudgets = this.listAnnualCondominiumBudgets.length
                // si no hay año le pongo el actu un año
                if( !this.condominiumDataEdit.selectedYear ){
                    //si listado actual es solo uno tomar el año de ese presupuesto
                    const defaultYear = ( totalListBudgets > 0 && totalListBudgets ===  1 ) ? this.listAnnualCondominiumBudgets[0].year : this.currentYear
                    this.setSelectedYearBudgetCondominium( defaultYear )
                }

                if( totalListBudgets > 0 ){

                    // del listado de presupuestos anuales obtengo el del año actual y verifico si su propiedad periodicity tenga valor
                    const selectedBudget = this.listAnnualCondominiumBudgets.find( budget => budget.year === this.condominiumDataEdit.selectedYear )

                    if( selectedBudget ){
                        this.setSelectedBudgetCondominium(selectedBudget)
                    }
                    const hasPeriodicity = selectedBudget && selectedBudget.periodicity

                    if( hasPeriodicity && !this.condominalFeesHousingByYear.length ){
                        const payload = { idBudget: selectedBudget.id, year: this.condominiumDataEdit.selectedYear}
                        await this.getFeesBudgetSelectedYear(  payload ) // pido los periodos del presupuesto condominal anual actual
                    }
                }
            },
            async updateData(data){
                const { year, idBudget } = data
                await this.setSelectedYearBudgetCondominium( year )
                await this.getAnnualsCondominalBudgets()
                await this.getFeesHousingByYear(year) // presupuestos por viviendas

                const payloadCalculate = { userId: this.user.idUser, idBudget }
                this.isExplodingData = true
                const { status, message } = await this.calcCondoFeeHousing(payloadCalculate)

                if ( status ) {
                    this.isExplodingData = false
				    showAlertMessage("Proceso terminado: ", "InfoIcon", `${message} : Puede actualizar el listado.`, "success", 4000, "bottom-right" ) 
                    const payload = { idBudget, year}
                    await this.getFeesBudgetSelectedYear(  payload )                     
                }
    
                if( !status ){
                    showAlertMessage("Sucedió un error", "InfoIcon", `Ocurrió un error: ${message}`, "danger", 4000, "bottom-right" )
                } 
                this.isExplodingData = false

            },
            isANumber(string){
                return string ? !isNaN( string ) && string > 0 : false
            },
            setSelectedBudget(budget){
                this.selectedBudget = budget
            },
            setIsCreatingBudget(bool){
                this.isCreatingBudget = bool
            },
            setIsUpdatingBudget(bool){
                this.isUpdatingBudget = bool
            },
            setIsDeletingBudget(bool){
                this.isDeletingBudget = bool
            },
            setIisLoadingAnnualFeesHousing(bool){
                this.isLoadingAnnualFeesHousingDetail = bool
            },
            async getAnnualsCondominalBudgets(){
                if( this.condominiumDataEdit.mainData ){ // si información general no es nulo
				    this.isLoadingAnnualsCondominalBudgets = true
                    // genero la lista de presupuestos anuales del condominio
                    const listBudgets = await this.fetchAnnualsCondominalsBudgets( this.condominiumDataEdit.mainData.id )
                    this.setListAnnualCondominiumBudgets( listBudgets )
				    this.isLoadingAnnualsCondominalBudgets = false
                }
            },
            // pido por periodos y por viviendas
            async getFeesBudgetSelectedYear(payload){
                this.setFeeHousingDetail(null)
                this.setFilterSearchCondominalFeesHousingByYear('')
                // await this.getFeesByPeriodsOfYear() // presupuestos por periodo anual
                await this.getFeesHousingByYear( payload.year ) // presupuestos por viviendas
            },
            async getFeesByPeriodsOfYear(year){
                this.isLoadingBudgetsByPeriods = true
                this.setCondominalBudgetByPeriods([]) // presupuesto desglozados por periodos
                const idCondo = this.$route.params.id
                const payload = { idBudget: this.condominiumDataEdit.selectedBudget.id, Periodicity: this.condominiumDataEdit.selectedBudget.periodicity, idHousing: null }
                const periods = await this.fetchCondominalBudgetByPeriods(payload)
                periods.forEach( period => {
                    period.isMarked = false
                })
                this.setCondominalBudgetByPeriods(periods)
                this.isLoadingBudgetsByPeriods = false
            },
            async getFeesHousingByYear(year){
                this.setCondominalFeesHousingByYear([])
                this.isLoadingAnnualFeesHousing = true
                const budget = this.listAnnualCondominiumBudgets.find( budget => budget.year === year )
                // si se encontró un presupuesto
                if( budget ){

                    this.setSelectedBudgetCondominium(budget)
                    const payload = { idBudget: budget.id }
                    const anualFeesHousing = await this.fetchCondominalBudgetFeesByYear( payload )
    
                    anualFeesHousing.forEach( period => {
                        period.isShowingDetails = false
                    })
    
                    this.setCondominalFeesHousingByYear(anualFeesHousing)
                }
                this.isLoadingAnnualFeesHousing = false

            },
            async getAnnualsCondominalBudgetsReport(){
                this.isLoadingExcelReport = true
                await this.fetchCondominalBudgetFeesByYearReport( { idBudget: this.condominiumDataEdit.selectedBudget.id, getExcel: 1 } )
                this.isLoadingExcelReport = false
            },
            async getCondominalFeesDetailsReport(){
                this.isLoadingExcelDetailsReport = true
                await this.fetchCondominalFeesDetailsReport( { idBudget: this.condominiumDataEdit.selectedBudget.id } )
                this.isLoadingExcelDetailsReport = false
            },
            openModalAddBudget(){
                this.$root.$emit("bv::show::modal", 'modal-condominium-budget-create')
            },
            async update(){
                this.isUpdatingCondominium = true
                const {id, name, address, zip, bankAccount, resorts, status, bankName, swift, bankAdress} = this.condominiumDataEdit.mainData
                const payload = {
                    idUser: this.user.idUser,
                    id,
                    name,
                    address,
                    zip,
                    bankAccount,
                    resorts,
                    status: Boolean(status),
                    bankName,
                    swift,
                    bankAdress
                }

                const response = await this.updateCondos( payload )
                if(response){
                    const { status, message } = response
                    if (status) showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
                }
                this.isUpdatingCondominium = false
            },
        },
    };
</script>
<style scoped>
.btns-head{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.loader span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #32bbca;
  margin: 0 4px;
  animation: bounce 0.8s infinite alternate;
}

.loader span:nth-child(2) {
  animation-delay: 0.1s;
}

.loader span:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}


</style>