<template>
	<b-modal id="modal-quotas-condo-housing-charge" :title="'Cargar Cuota a vivienda: '+ (selectedHousing ? selectedHousing.housingnumber: '') " ok-only hide-footer no-close-on-backdrop ref="modalCHsingle" @hide="modalWasClose">
		<b-overlay :show="isSavingCuota" >
			<ValidationObserver ref="modalQuotasCharge" v-slot="{ invalid }">
				<b-form @submit.prevent="handlerQuotaCharge">

					<b-row>

						<b-col md="12">
							<ValidationProvider rules="required" name="Monto">
								<b-form-group label="Monto" slot-scope="{ valid, errors }">
									<b-form-input
										type="text"
										:state="errors[0] ? false : valid ? true : null"
										v-model="chargeCuota.amount"
										placeholder="0.00"
										@keypress="validateNumberWithDecimal"
									>
									<!-- @blur="formatAmount(chargeCuota.amount)" -->
									</b-form-input>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<b-col md="12">
							<ValidationProvider rules="required" name="Fecha">
								<b-form-group label="Fecha" slot-scope="{ valid, errors }">
									<b-form-input
										type="date"
										:state="errors[0] ? false : valid ? true : null"
										v-model="chargeCuota.operationDate"
										:min="minMaxDateFromYear.firstDay"
										:max="minMaxDateFromYear.lastDay"
									>
									</b-form-input>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<b-col md="12">
							<ValidationProvider rules="required" name="concepto">
								<b-form-group label="Concepto" slot-scope="{ valid, errors }">
									<b-form-select :state="errors[0] ? false : valid ? true : null" v-model="chargeCuota.movementType" >
											<option :value="null" disabled>Seleccione una opción</option>
											<option v-for="concept in concepts" :key="concept.value" :value="concept.value" > {{ concept.name }} </option>
										</b-form-select>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<b-col md="12" v-if="requireSpecifyMovement">
							<ValidationProvider rules="required" name="tipo de cargo">
								<b-form-group label="Tipo de cargo" slot-scope="{ valid, errors }">
									<b-form-select :state="errors[0] ? false : valid ? true : null" v-model="chargeCuota.chargeType" >
											<option :value="null" disabled>Seleccione una opción</option>
											<option v-for="movement in movementTypes" :key="movement.value" :value="movement.value" > {{ movement.name }} </option>
										</b-form-select>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<b-col md="12">
							<ValidationProvider rules="" name="Notas">
								<b-form-group label="Para agregar notas">
									<b-form-textarea
										v-model="chargeCuota.notes"
										placeholder="Escribe la nota"
										:maxlength="100"
										rows="2"
									>
									</b-form-textarea>
								</b-form-group>
							</ValidationProvider>
						</b-col>

					</b-row>

					<div class="btn-modal">
						<b-button type="submit" :disabled="invalid" variant="primary"> Guardar </b-button>
					</div>
				</b-form>
			</ValidationObserver>
		</b-overlay>
	</b-modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { numberWithCommas, showAlertMessage, toCurrency } from "@/helpers/helpers";
import { utils } from "@/modules/fivesClub/mixins/utils";

export default {
	mixins: [utils],
	props:{
		selectedHousing: {
			type: Object,
			default: null
		},
    },
	data(){
		return {
			chargeCuota: {
				amount: '',
				movementType: null,
				operationDate: null,
				chargeType: null,
				notes: '',
			},
			isSavingCuota: false,
            fieldsFees: [
                { key: 'isMarked', label: '*', class: 'text-center', thStyle: { width: "50px" }  },
                {
                    key: 'period', label:'Periodo', class:'text-justify',
                    thStyle: { width: "280px" }
                },
                // {   key:'balanceAmount', label:'Cuota',
                //     formatter: (value, key, item) => {return this.formatMoney({value, item}) }
                // },

            ],
			concepts: [

				{
					value: 3, name: 'Cuota extraordinaria', type: -1
				},
				{
					value: 4, name: 'Cuota de recargo', type: -1
				},
				{
					value: 5, name: 'Cuota de intereses', type: -1
				},
				{
					value: 6, name: 'Saldo inicial', type: null
				},
				{
					value: 7, name: 'Devolución', type: null
				}
			],
			movementTypes: [
				{value: 1, name: 'Cargo', type: -1},
				{value: 2, name: 'Abono', type: 1}
			]

		}
	},

	computed: {
        ...mapState('fivesClubCatalogs', ['housing','selectedFeeHousing','condominalBudgetByPeriods','condominiumDataEdit']),
		...mapState('auth', ['user']),
		minMaxDateFromYear(){
			const year = this.condominiumDataEdit?.selectedYear || new Date().getFullYear()
			const firstDay = new Date(year, 0, 1).toISOString().split('T')[0];
			const lastDay = new Date(year, 11, 31).toISOString().split('T')[0];

			return { firstDay, lastDay }
		},
		requireSpecifyMovement(){
			const optionSelected = this.concepts.find(concept => concept.value == this.chargeCuota.movementType)
			return optionSelected ? !optionSelected.type : false
		}
    },
	methods:{

		...mapActions('fivesClubCatalogs', ['saveCondoFeeCalculation','fetchCondoFeeHousingDetail']),
        ...mapMutations('fivesClubCatalogs',['setCondominiumFeeSelected']),
        ...mapMutations('fivesClubCatalogs',['setIsLoadingDetailsCondominiumFee', 'setDetailsCondominiumFee']),

        ...mapMutations('fivesClubCatalogs',['setIsLoadingDetailsCondominiumFee', 'setDetailsCondominiumFee','setCondominalFeesHousingByYear','setFeeHousingDetail']),


		formatAmount(amount){
			this.chargeCuota.amount = numberWithCommas(amount)
		},
		formatMoney(data){
            const {value, item} = data
            return toCurrency(value, item?.currency?.code)
        },
		setMarked(item){
			this.$emit('set-marked', item)
        },
		async handlerQuotaCharge(){

			this.isSavingCuota = true
            this.$emit('set-is-loading-annual-fees-housing-from-modal', true)

			this.positiveOrNegative()

			const payload  = {
				...this.chargeCuota,
				idCondoFee: parseInt(this.condominiumDataEdit.selectedBudget.id),
				idHousing: this.selectedHousing.housingid,
				isPaid: 0,
				isMassive: 0,
				userId: this.user.idUser,
				// ids: selectedPeriods.map( period => period.idPeriod ),
			}
			// const resp = { status: true, message: 'success'}
			// const { status, message } = resp

			const { status, message } = await this.saveCondoFeeCalculation(payload)

			if ( status ) {
				this.setFeeHousingDetail(null)
				showAlertMessage("Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right" )
                Object.assign(this.$data.chargeCuota, this.$options.data().chargeCuota);
                this.$refs.modalQuotasCharge.reset(); //reset form
				this.$root.$emit("bv::hide::modal", 'modal-quotas-condo-housing-charge') // cierro modal
				this.$emit('update-housing-and-detail', { ...this.selectedHousing, idBudget:this.condominiumDataEdit.selectedBudget.id } )
            }


			if( !status ){
                showAlertMessage("Sucedió un error", "InfoIcon", `Ocurrió un error: ${message}`, "danger", 4000, "bottom-right" )
			}
			this.$emit('set-is-loading-annual-fees-housing-from-modal', false)
			this.isSavingCuota = false
		},

		positiveOrNegative(){
			const optionSelected = this.concepts.find(concept => concept.value == this.chargeCuota.movementType)

			if (!!optionSelected){
				let originalAmount = structuredClone(this.chargeCuota.amount)
				let newAmount = 0
				let chargeType

				if (originalAmount < 0) originalAmount = originalAmount * -1
				if (this.chargeCuota.chargeType) chargeType = this.movementTypes.find(charge => charge.value == this.chargeCuota.chargeType)?.type

				newAmount = originalAmount * (optionSelected.type || chargeType)
				this.chargeCuota.amount = newAmount
			}
		},
		modalWasClose(){
			Object.assign(this.$data.chargeCuota, this.$options.data().chargeCuota);
		}
	}
}
</script>

<style scoped>
.btn-modal{
	display: flex;
	justify-content: flex-end;
}

.period-h{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-modal{
	display: flex;
    align-items: flex-start;
    justify-content: space-between;
	background-color: #f8f8f8;
	border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
	padding: 0.8rem 1.4rem;
	border-top-left-radius: calc(0.358rem - 1px);
    border-top-right-radius: calc(0.358rem - 1px);
}
.close-modal{
	padding: 0.2rem 0.62rem;
    box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
    border-radius: 0.357rem;
    background: #fff;
	margin: -0.8rem -1.4rem -0.8rem auto;
	transform: translate(8px, -2px);
	opacity: 1;
    transition: all 0.23s ease 0.1s;
    position: relative;
	border: 0;
	font-size: 1rem;
}

</style>