<template>
	 <b-modal id="modal-massive-budget" :title="'Cobro de cuotas: ' + budget.year" ok-only hide-footer no-close-on-backdrop ref="modal-massive-budget">
		<b-overlay :show="isUpdatingQuotas ">
			<ValidationObserver ref="updateCondominiumForm" v-slot="{ invalid }">
				<b-form @submit.prevent="updateBudgetMassive">
                    
                    <b-overlay :show="isLoadingBudgetsByMassivePeriods" >
                    
                        <b-table
                            :fields="fieldsFees"
                            :items="condominalBudgetByPeriods"
                            class="mb-1 "
                            show-empty
                            empty-text="No hay registros de periodos a mostrar"
                            :thead-class="!condominalBudgetByPeriods.length ? 'd-none' :''"
                            small
                            bordered
                        >

                            <template #head(isMarked)>
                                <b-form-group>
                                    <b-form-checkbox
                                        v-model="isMarkedAll"
                                        @change="setMarkedAll"                                    
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </template>

                            <template #cell(isMarked)="row">
                                <b-form-checkbox
                                    v-model="row.item.isMarked"
                                    @change="setMarked(row.item)"                                
                                >
                                </b-form-checkbox>
                            </template>

                        </b-table>
                    </b-overlay>

                    <b-row v-if="condominalBudgetByPeriods.length && !isLoadingBudgetsByMassivePeriods">
                        <b-col md="12">
							<ValidationProvider rules="required" name="Fecha de pago">
								<b-form-group label="Fecha de pago" slot-scope="{ valid, errors }">
									<b-form-input
										type="date"
										:state="errors[0] ? false : valid ? true : null"
										v-model="quotaCondominium.operationDate"
									>
									</b-form-input>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</b-col>

                        <b-col md="12">
                            <b-form-group label="Tipo de pago">
                                <b-badge variant="success">  {{ paymentType.name }} </b-badge>
                            </b-form-group>
                        </b-col>


						<b-col md="12">
							<ValidationProvider rules="" name="Notas">
								<b-form-group label="Para agregar notas">
									<b-form-textarea
										v-model="quotaCondominium.notes"
										placeholder="Escribe la nota"
										:maxlength="100"
										rows="2"
									>
									</b-form-textarea>
								</b-form-group>
							</ValidationProvider>
						</b-col>

                    </b-row>


					<div class="btn-modal mt-2" v-if="condominalBudgetByPeriods.length ">
						<b-button type="submit"  variant="primary"  :disabled="invalid || isUpdatingQuotas">
							Actualizar
						</b-button>
					</div>
                    
                    
                    <b-alert show variant="danger" class="p-1 text-center" v-if=" !condominalBudgetByPeriods.length " >
                        No se encontró periodo que le falte aplicarle un cobro de cuotas.                                                                                 
                    </b-alert>
                    

				</b-form>
			</ValidationObserver>
		</b-overlay>

    </b-modal>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import Ripple from 'vue-ripple-directive'
import { showAlertMessage, toCurrency } from '@/helpers/helpers'
import { seedBudgetCondosDetail } from '@/data/fivesClub'

export default {
    directives: {Ripple, },
    props:{        
        isLoadingBudgetsByMassivePeriods:{
            type: Boolean,
            required: false,
        },
        budget:{
			type: Object,
			required: true,
		}
    },
    data() {
        return {

            fieldsFees: [
                { key: 'isMarked', label: '--', class: 'text-center', thStyle: { width: "50px" }  },
                {
                    key: 'period', label:'Periodo', class:'text-justify',
                    thStyle: { width: "280px" }
                },
                {   key:'balanceAmount', label:'Cuota',
                    formatter: (value, key, item) => {return this.formatMoney({value, item}) }
                },

            ],

            detailsPeriod: seedBudgetCondosDetail,
            isUpdatingQuotas: false,
            quotaCondominium: {
				isMassive: true,
				notes: '',
				operationDate: '',
			},
            
        }
    },
    computed:{
        ...mapState('start', ['hotels']),
        ...mapState('fivesClubCatalogs', ['condominalBudgetByPeriods','isMarkedAllBudgetByPeriods']),
		...mapState('auth', ['user']),

        isMarkedAll: {
            get(){
                return this.isMarkedAllBudgetByPeriods
            },
            set(isMarked) {
                this.setIsMarkedAllBudgetByPeriods(isMarked)
            }
        }        ,
        paymentType(){
			return { id: 1, name: 'Rendimiento'}
		},
        // traigo el arreglo de ids con booleano isMarked true
        idsMarked(){
            return this.condominalBudgetByPeriods.filter( period => period.isMarked).map( period => period.idPeriod )
        },        
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['fetchDetailBudgetPeriod','fetchAnnualsCondominalsBudgets','saveCondoFeeCalculation']),
        ...mapMutations('fivesClubCatalogs',['setBudgetByPeriodSelected','setIsLoadingDetailsBudgetByPeriod','setDetailsBudgetByPeriod','setIsMarkedAllBudgetByPeriods','setMarkedBudgetByPeriod',
        'setAllMarkedBudgetByPeriods','setSelectedYearBudgetCondominium','setCondominalFeesHousingByYear',
'setFeeHousingDetail']),

        setMarkedAll(isMarked){
            this.setIsMarkedAllBudgetByPeriods(isMarked)
            this.setAllMarkedBudgetByPeriods({isMarked})
        },
        setMarked(item){
            const {idPeriod, isMarked } = item
            this.setMarkedBudgetByPeriod({idPeriod, isMarked})
        },
        formatMoney(data){
            const {value, item} = data
            return toCurrency(value, item?.currency?.code)
        },
        async updateBudgetMassive(){

            if( !this.idsMarked.length ){
                return showAlertMessage('No tiene seleccionado un periodo', 'InfoIcon', 'Para continuar debe seleccionar mínimo un periodo.', 'danger', 4000, 'bottom-right' )
            }

            this.isUpdatingQuotas = true

			const budget =  JSON.parse( JSON.stringify( this.budget ) )
			const year = JSON.parse( JSON.stringify( budget.year ) ) // lo necesito, para ocupar en setSelectedYearBudgetCondominium

            const payload = {
                ...this.quotaCondominium,
                ids: this.idsMarked,
				isPaid: 1,
				isMassive: 1,
				idCondoFee: parseInt(budget.id),
				userId: this.user.idUser,
                paymentType: this.paymentType.id,
            }
            this.setMarkedAll(false)

            const { status, message } = await this.saveCondoFeeCalculation(payload)

            if ( status ) {
                this.setCondominalFeesHousingByYear([])
                this.setFeeHousingDetail(null)

                this.isUpdatingQuotas = false
                this.setMarkedAll(false)
                showAlertMessage('Cobro terminado', 'InfoIcon', `Cobro masivo terminado: ${message}`, 'success', 4000, 'bottom-right' )
                Object.assign(this.$data.quotaCondominium, this.$options.data().quotaCondominium) //reseteo los valores iniciales de quotaCondominium
                this.$root.$emit("bv::hide::modal", 'modal-massive-budget')// cierro modal
				this.setSelectedYearBudgetCondominium( year ) // seteo el nuevo año seleccionado
                // TODO actualizar listdo de presupuestos, peridos y viviendas presupuestos
				this.$emit('update-data-after-modify', year )
            }

            if( !status ){
                showAlertMessage("Sucedió un error", "InfoIcon", `Ocurrió un error: ${message}`, "danger", 4000, "bottom-right" )
                this.isUpdatingQuotas = false
			}
        },
    }
}
</script>

<style scoped>
.btn-modal{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}


.gray-text{
  color: rgb(170, 170, 170);
}
</style>