<template>
   <ValidationObserver ref="updateCondominiumForm" v-slot="{ invalid }" v-if="condominiumDataEdit.mainData">
        <b-form @submit.prevent="update">
            <div class="border rounded mb-1">
                <b-row class="p-1">
                    <b-col cols="12"><h5>Información General</h5></b-col>
                    <b-col md="6">
                        <ValidationProvider rules="required" name="name">
                            <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                                <b-form-input
                                    type="text"
                                    v-model="condominiumDataEdit.mainData.name"
                                    :state="errors[0] ? false : valid ? true : null"
                                    placeholder="Ingrese el Nombre"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider name="Hotel" rules="required">
                            <b-form-group label="Resort" slot-scope="{ valid, errors }">
                                <b-form-select
                                    :state="errors[0] ? false : valid ? true : null"
                                    v-model="condominiumDataEdit.mainData.resorts"
                                >
                                    <option value="">Seleccione hotel</option>
                                    <option
                                        v-for="hotel in hotels"
                                        :key="hotel.id"
                                        :value="hotel.id"
                                    >
                                        {{ hotel.name }}
                                    </option>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider rules="required" name="address">
                            <b-form-group label="Dirección" slot-scope="{ valid, errors }">
                                <b-form-input
                                    type="text"
                                    v-model="condominiumDataEdit.mainData.address"
                                    :state="errors[0] ? false : valid ? true : null"
                                    placeholder="Ingrese la Dirección"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider rules="required" name="zip">
                            <b-form-group label="Código postal" slot-scope="{ valid, errors }">
                                <b-form-input
                                    type="number"
                                    v-model="condominiumDataEdit.mainData.zip"
                                    :state="errors[0] ? false : valid ? true : null"
                                    placeholder="Ingrese el Código postal"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                </b-row>
            </div>
            <div class="border rounded mb-1">
                <b-row class="p-1">
                    <b-col cols="12"><h5>Información Bancaria</h5></b-col>

                    <b-col md="6">
                        <ValidationProvider rules="required" name="bankName">
                            <b-form-group label="Banco" slot-scope="{ valid, errors }">
                                <b-form-input
                                    type="text"
                                    v-model="condominiumDataEdit.mainData.bankName"
                                    :state="errors[0] ? false : valid ? true : null"
                                    placeholder="Ingrese la Cuenta bancaria"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider rules="required" name="bankAccount">
                            <b-form-group label="Cuenta bancaria" slot-scope="{ valid, errors }">
                                <b-form-input
                                    type="text"
                                    v-model="condominiumDataEdit.mainData.bankAccount"
                                    :state="errors[0] ? false : valid ? true : null"
                                    placeholder="Ingrese la Cuenta bancaria"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider rules="required" name="swift">
                            <b-form-group label="Swift" slot-scope="{ valid, errors }">
                                <b-form-input
                                    type="text"
                                    v-model="condominiumDataEdit.mainData.swift"
                                    :state="errors[0] ? false : valid ? true : null"
                                    placeholder="Ingrese Swift"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider rules="required" name="bankAdress">
                            <b-form-group label="Domicilio del Banco" slot-scope="{ valid, errors }">
                                <b-form-input
                                    type="text"
                                    v-model="condominiumDataEdit.mainData.bankAdress"
                                    :state="errors[0] ? false : valid ? true : null"
                                    placeholder="Ingrese la Cuenta bancaria"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <div class="float-left pt-2 mt-1">
                            <b-form-checkbox
                                v-model="condominiumDataEdit.mainData.status"
                                :value="true"
                                class="custom-control-success"
                            >
                                Activado
                            </b-form-checkbox>
                        </div>
                    </b-col>

                </b-row>
            </div>
            <b-row>
                <b-col>
                    <div class="float-right">
                        <b-button type="submit" class="btn-block" variant="primary"  :disabled="invalid || isUpdatingCondominium">
                            <b-spinner small v-if="isUpdatingCondominium"/> Actualizar
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-form>
    </ValidationObserver>
</template>

<script>

import { mapState } from "vuex"
export default {
    props:{
        isUpdatingCondominium: {
            type: Boolean,
            required: true,
        },
        isLoadingCondominiumData: {
            type: Boolean,
            required: true,
        },       
    },
    computed:{
        ...mapState('start', ['hotels']),
        ...mapState('fivesClubCatalogs', ['condominiumDataEdit']),                                
    },
    methods:{
        update(){
            this.$emit('update-condominium');
        },
    }
}
</script>